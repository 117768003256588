window.esign = window.esign || {};

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    $document: $(document),
    $window: $(window),
    $html: $('html'),

    // Header
    $header: $('.page-header'),

    // navigation
    $nav: $('.main-nav__wrap'),
    $navTrigger: $('.main-nav__trigger'),
    $modaalTrigger: $('.js-modaal-trigger'),
    $subNav: $('.sub-nav__wrap'),
  };
};

esign.init = function () {

  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [752,0],
    lazy: true
  });

  esign.gaDevelopment();
  esign.cacheSelectors();

  esign.navigation();
  esign.responsiveVideos();
  esign.formAjax();
  esign.slideshows();
  esign.matchHeight();
  esign.ellipsis();
  esign.images();
  esign.select();
  esign.accordion();
  esign.floatingLabels();
  esign.fancybox();
  esign.newsFilter();
};

esign.newsFilter = function () {
  $('#filter-location').on('change', function () {
    var url = $('#filter-location option:selected').val();
    window.location.replace(url);
  });
};

esign.fancybox = function () {
  $('[data-fancybox]').fancybox({
    buttons: [
      'close',
      'share',
    ],
    youtube : {

    },
    vimeo : {
      color : 'F8AE15'
    },
    protect: true,
  });
};

esign.floatingLabels = function () {
  if (document.createElement("input").placeholder === undefined) {
    $('.floating-label input, .floating-label textarea').addClass('active');
    $('.floating-label label').addClass('active');
  } else {
    $('.floating-label').each(function (index, element) {
      $(element).find('input, textarea').on('select keydown', function () {
        $(this).parent().find('label').addClass('active');
        $(this).addClass('active');
      });

      $(element).find('input, textarea').on('keyup blur ', function () {
        if ($(this).parent().find('input, textarea').val().length === 0) {
          $(this).parent().find('label').removeClass('active');
          $(this).removeClass('active');
        }
      });
    });
  }
};

esign.accordion = function () {
  /* Accordion
   * uses .accordion-trigger as a[href="#id"], label[for="id"], div[data-href="#id"]
   *     .accordion-content as wrap
   */
  $('.accordion-trigger').click(function(e) {
    e.preventDefault();

    var $this = $(this),
      $target = null;

    // get target
    if($this.is('a')) {
      $target = $($this.attr('href'));
    } else if ($this.is('label')) {
      $target = $('#' + $this.attr('for')).closest('.accordion-content');
    } else if($this.is('div')) {
      $target = $($this.data('href'));
    }

    // toggle target
    if($target !== null) {
      $this.toggleClass('active');
      $target.toggle('fast');
    }

  });
};

esign.select = function () {
  $('.js-select').select2({
    minimumResultsForSearch: -1,
    width: 220,
  });
};

esign.images = function () {
  $('.grid__image').each(function () {
    $(this).imagesLoaded({
      background: true
    }, function (instance) {
      $(instance.elements).addClass('loaded');
    });
  })
};

esign.ellipsis = function () {
  $('.js-ellipsis').dotdotdot({
    watch: true,
  });
};

esign.matchHeight = function () {
  $('.mh > *').matchHeight();
};

esign.slideshows = function () {
  var $slideshow = $('.js-slideshow');

  $slideshow.on('init', function(event, slick){
    $(".slideshow__current").text(parseInt(slick.currentSlide + 1) + '/' + slick.slideCount);

    if (location.hash !== '' && location.hash !== '#') {
      var target = $(location.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

      console.log(target);
      if (target.length) {
        esign.scrollToSection(target);
      }
    }
  });

  $slideshow.on('afterChange', function(event, slick, currentSlide){
    $(".slideshow__current").text(parseInt(currentSlide + 1) + '/' + slick.slideCount);
  });

  $slideshow.on('setPosition', function () {
    $slideshow.find('.slick-slide .slideshow__body').matchHeight();
  });

  $slideshow.slick({
    draggable: false,
    fade: true,
    nextArrow: $('.slideshow__next'),
    prevArrow: $('.slideshow__prev')
  });
};

esign.navigation = function () {
  esign.cache.$navTrigger.click(function(e) {
    e.preventDefault();
    esign.cache.$modaalTrigger.trigger('click');
  });

  esign.cache.$modaalTrigger.modaal();

  esign.cache.$window.resize(function() {
    if (esign.cache.$window.width() > 767) {
      esign.cache.$modaalTrigger.modaal('close');
    }
  });

  // Anchor links
  $('a[href*="#"]:not(".grid__video")')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
      if (
        location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
        &&
        location.hostname === this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

        if (target.length) {
          event.preventDefault();
          esign.scrollToSection(target);
        }
      }
    });

  if (location.hash !== '' && location.hash !== '#') {
    var target = $(location.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

    if (target.length) {
      esign.scrollToSection(target);
    }
  }

  // Change size on scroll
  esign.cache.$window.scroll(function () {
    scrollPosition();
  }).scroll();

  function scrollPosition() {
    var className = 'small';
    esign.cache.$header = $('.page-header');

    if (documentTopPosition() > 250) {
      esign.cache.$header.addClass(className);
    } else {
      esign.cache.$header.removeClass(className);
    }
  }

  function documentTopPosition() {
    return esign.cache.$document.scrollTop();
  }
};

esign.scrollToSection = function(target) {
  var distanceToCover = Math.abs($(document).scrollTop() - target.offset().top);
  var timeToScroll = distanceToCover / 7 + 250;

  $('html, body').stop(true).animate({
    scrollTop: target.offset().top - 120
  }, timeToScroll);
}

esign.responsiveVideos = function () {
  $('iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]').wrap('<div class="video-container"></div>');
};

// Ajax newsletter subscribe
esign.formAjax = function () {
  $('.form-ajax').submit(function(e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr('action'), $form.serializeArray(), function(data) {
      if(data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find('.result').html(data.result);
        $form.find('button, input[type="submit"]').removeAttr('disabled');
      }
    });

    e.preventDefault();
    return false;
  });
};

// Log ga calls in development
esign.gaDevelopment = function() {
  if (typeof ga === typeof undefined) {
    window.ga = function() {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg = '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
      console.log('%c' + msg, 'background: #ff9800; color: #fff;');
    };
  }
};

require([
  'requirejs/require',
  'jquery/dist/jquery',
  'js/polyfills/native-console',
  'response.js/response',
  'jquery-touchswipe/jquery.touchSwipe',
  'modaal/dist/js/modaal',
  'slick-carousel/slick/slick',
  'jquery-match-height/dist/jquery.matchHeight',
  'dotdotdot/src/js/jquery.dotdotdot',
  'imagesloaded/imagesloaded.pkgd',
  'select2/dist/js/select2',
  '@fancyapps/fancybox/dist/jquery.fancybox',
], function() {
  // Initialize on docready
  $(esign.init);
});

define("../resources/assets/js/esign", function(){});

